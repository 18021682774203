const initState = {
  quotes: [],
  page: 1,
  limit: 10,
  count: 0,
};

const QuoteReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_QUOTES":
      return {
        ...state,
        quotes: action.payload.data,
        page: action.payload.page,
        limit: action.payload.limit,
        count: action.payload.count,
      };
    case "RESET_QUOTES_STATE":
      return {
        ...state,
        ...initState,
      };
    default:
      return { ...state };
  }
};

export default QuoteReducer;
