export const setTopMenu = (value, page, limit, count) => {
  return {
    type: "SET_TOP_MENU",
    payload: { data: value, page, limit, count },
  };
};
export const setOneTopMenu = (value) => {
  return {
    type: "SET_ONE_TOP_MENU",
    payload: { data: value },
  };
};
