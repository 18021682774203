const initState = {
  saMenuList: [],
  saMenu: {},
  saPage: 1,
  saLimit: 10,
  saCount: 0,
  igMenuList: [],
  igMenu: {},
  igPage: 1,
  igLimit: 10,
  igCount: 0,
  hcpMenuList: [],
  hcpMenu: {},
  hcpPage: 1,
  hcpLimit: 10,
  hcpCount: 0,
  ceMenuList: [],
  ceMenu: {},
  cePage: 1,
  ceLimit: 10,
  ceCount: 0,
  prMenuList: [],
  prMenu: {},
  prPage: 1,
  prLimit: 10,
  prCount: 0,
};
export const Menu = (state = initState, action) => {
  switch (action.type) {
    case "SET_SA_MENU": {
      return {
        ...state,
        saMenuList: action.payload.data,
        saPage: action.payload.page,
        saLimit: action.payload.limit,
        saCount: action.payload.count,
      };
    }
    case "SET_IG_MENU": {
      return {
        ...state,
        igMenuList: action.payload.data,
        igPage: action.payload.page,
        igLimit: action.payload.limit,
        igCount: action.payload.count,
      };
    }
    case "SET_HCP_MENU": {
      return {
        ...state,
        hcpMenuList: action.payload.data,
        hcpPage: action.payload.page,
        hcpLimit: action.payload.limit,
        hcpCount: action.payload.count,
      };
    }
    case "SET_CE_MENU": {
      return {
        ...state,
        ceMenuList: action.payload.data,
        cePage: action.payload.page,
        ceLimit: action.payload.limit,
        ceCount: action.payload.count,
      };
    }
    case "SET_PR_MENU": {
      return {
        ...state,
        prMenuList: action.payload.data,
        prPage: action.payload.page,
        prLimit: action.payload.limit,
        prCount: action.payload.count,
      };
    }
    case "SET_ONE_TOP_MENU": {
      return {
        ...state,
        oneTopMenu: action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
};
