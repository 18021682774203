const initState = {
    storeList: [],
    store: {},
    page: 1,
    limit :10,
    count : 0
  };
  export const Store = (state = initState, action) => {
    switch (action.type) {
      case "SET_STORES": {
        return {
          ...state,
          storeList: action.payload.data,
          page: action.payload.page,
          limit: action.payload.limit,
          count: action.payload.count,
        };
      }
      default: {
        return state;
      }
    }
  };
  