import history from '../../../app/history';
import { api } from '../../../api/api';
import { setNews } from '../news/newsAction';
import { setOneTopMenu } from '../b2cCms/topMenuAction';
import { setPromotion, setActivePromotion } from '../promotion/promotionAction';
import { setSubscriber } from '../subscriber/subscriberAction';
import { toastr } from 'react-redux-toastr';
import { setCatalogue } from '../Catalogue/CatalogueAction';
import { setMarketingCategory } from '../marketingCategory/marketingCategoryAction';
import { setTrainingCategory } from '../trainingCategory/trainingCategoryAction';
import {
  setCategory,
  setTopic,
  setFileType,
} from '../productAttributes/productAttributes';
import {
  setMarketingItem,
  setJournalsItem,
  setNewsLettersItem,
  setEventsItem,
} from '../marketingItem/marketingItemAction';
import { setTrainingItem } from '../trainingItem/trainingItemAction';
import { setAuditTrail } from '../auditTrail/auditTrailAction';
import { setStockSheet } from '../stockSheet/stockSheetAction';
import { setRelation } from '../relation/relationAction';
import { setCompany, setSummary } from '../company/companyAction';
import { setCompanyUsers } from '../companyUsers/companyUsersAction';
import { setRepContactAccount } from '../repContactAccounts/repContactAccount';
import { setRepContact } from '../repContact/repContact';
import { setSwitchAccount } from '../switchAccount/switchAccountAction';
import {
  setMyCatalogue,
  setAllCatalogueProducts,
} from '../myCatalogue/myCatalogueAction';
import { setPayment } from '../payment/paymentAction';
import { setQuotes } from '../quote/quoteAction';
import { setInvoice } from '../invoice/invoiceAction';
import { setCart } from '../cart/cartAction';
import {
  setAdminDashboardUsers,
  setAdminDashboardOrders,
  setAdminDashboardRevenue,
  setAdminDashboardProducts,
} from '../adminDashboard/adminDashboardAction';
import {
  setCartCount,
  setCategoryList,
} from '../searchMyCatalogue/searchMyCatalogueAction';
import {
  setImagesCategory,
  setImagesItem,
  setHCPLinksItem,
  setPRLinksItem,
} from '../images/imagesAction';
import {
  setSAMenu,
  setIGMenu,
  setHCPMenu,
  setCEMenu,
  setPRMenu,
} from '../b2cCms/MenuAction';
import { setCareers } from '../b2cCms/careersAction';
import { setStores } from '../b2cCms/storeAction';
import { setPages, setAllPages } from '../b2cCms/pageAction';
import { setLoginBackground } from '../b2cCms/backgroundAction';

import { setB2bBlocksData } from '../b2bCms/homeBlockAction';
import { setPageConfig } from '../pageConfig/pageConfigAction';
import { setMemberSearch } from '../memberSearch/memberSearchAction';
import {
  setHomeHeader,
  setHomeBanner,
  setHomeSponsor,
  setHomeCongress,
} from '../banner/bannerAction';
import { setTransactions } from '../transaction/transactionAction';

export const checkCheckoutQuoteOrderStatusApi = (id) => {
  return async (dispatch, store) => {
    let res = await api(
      `/quoteOrderPay/status`,
      { checkoutId: id },
      'post'
    ).catch((err) => {});
    if (res) {
      if (res.status !== 200) {
        // toastr.error("Gyms",'Some error Occured. Try again');
        // console.log("error");
        toastr.error(`${res.data.error || 'Order Failed'}`);
        return false;
        // history.push("/userinfo");
      } else {
        toastr.success('Order Placed Successful');
        return true;
        // history.push("/userinfo");
      }
    }
  };
};

export const checkPaymentStatusApi = (id) => {
  return async (dispatch, store) => {
    let res = await api(`/pay/status`, { checkoutId: id }, 'post').catch(
      (err) => {}
    );
    if (res) {
      if (res.status !== 200) {
        // toastr.error("Gyms",'Some error Occured. Try again');
        // console.log("error");
        toastr.error(`${'Payment Failed'}`);
        return false;
        // history.push("/userinfo");
      } else {
        toastr.success('Payment Successful');
        return true;
        // history.push("/userinfo");
      }
    }
  };
};

export const postQuote = async (id, PONumber) => {
  let response = await api(
    `/quoteProcess/${id}`,
    { PONumber: PONumber },
    'post'
  );
  return response;
};

export const postOrderApi = async (
  id,
  PONumber,
  shippingOption,
  partialShipping
) => {
  let response = await api(
    `/orderProcess/${id}`,
    {
      PONumber: PONumber,
      shippingOption: shippingOption,
      partialShipping: partialShipping,
    },
    'post'
  );
  return response;
};

export const postQuoteOrderApi = async (
  id,
  PONumber,
  shippingOption,
  partialShipping,
  salesOrder
) => {
  let response = await api(
    `/quoteOrderProcess/${id}`,
    {
      PONumber: PONumber,
      shippingOption: shippingOption,
      partialShipping: partialShipping,
      salesOrder: salesOrder,
    },
    'post'
  );
  return response;
};

export const TransactionsApi = (
  search = '',
  page = 1,
  limit = 10,
  store = ''
) => {
  return async (dispatch) => {
    let userData = JSON.parse(localStorage.getItem('member'));
    store =
      userData.role === 'store_admin' || userData.storeCode
        ? userData.storeCode
        : '';
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/transactions?limit=${data.limit}&page=${page}&search=${search}&store=${store}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setTransactions(
          response.data.data,
          page,
          limit,
          response.data.totalTransactions
        )
      );
      // }
    } else {
      dispatch(setTransactions([]));
    }
  };
};
export const OrdersApi = (search = '', page = 1, limit = 10, store = '') => {
  return async (dispatch) => {
    let userData = JSON.parse(localStorage.getItem('member'));
    store =
      userData.role === 'store_admin' || userData.storeCode
        ? userData.storeCode
        : '';
    const data = {
      search,
      page,
      limit,
    };

    let response = await api(
      `/orders?backOrder=false&limit=${data.limit}&page=${page}&search=${search}&store=${store}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setPayment(response.data.data, page, limit, response.data.totalOrders)
      );
    } else {
      dispatch(setPayment([]));
    }
  };
};

export const checkCheckoutStatusApi = (id) => {
  return async (dispatch, store) => {
    let res = await api(`/pay/status`, { checkoutId: id }, 'post').catch(
      (err) => {}
    );
    if (res) {
      if (res.status !== 200) {
        // toastr.error("Gyms",'Some error Occured. Try again');
        // console.log("error");
        toastr.error(`${res.data.error || 'Order Failed'}`);
        return false;
        // history.push("/userinfo");
      } else {
        toastr.success('Payment Successful');
        return true;
        // history.push("/userinfo");
      }
    }
  };
};

export const relatedProductsApi = async (store, account, id) => {
  let response = await api(
    `/relatedProducts/${store}/${id}?account=${account}`,
    {},
    'get'
  );
  if (response) {
    return response;
  }
};
export const getMenuBySlugApi = (slug) => {
  return async (dispatch) => {
    let response = await api(`/menu/slug/${slug}`, {}, 'get');
    if (response && response.status === 200) {
      dispatch(setOneTopMenu(response.data.data));
    } else {
      dispatch(setOneTopMenu({}));
    }
  };
};
export const getProductBySlug = async (store, account, slug) => {
  let response = await api(
    `/productDetails/${store}/${slug}?account=${account}`,
    {},
    'get'
  );
  if (response) {
    return response;
  }
};

export const repairsApi = (id, page = 1, limit = 10) => {
  return async (dispatch) => {
    let response = await api(
      `/repairs/${id}?page=${page}&limit=${limit}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setMemberSearch(response.data.data, page, limit, response.data.total)
      );
    } else {
      dispatch(setMemberSearch([]));
    }
  };
};

export const updateConfigApi = (page, data) => {
  return async (dispatch) => {
    let response = await api(`/pageConfig?page=${page}`, data, 'patch');
    if (response && response.status === 200) {
      toastr.success('Banner updated successfully');
      return true;
    } else {
      toastr.error('Banner not updated');
      return false;
    }
  };
};

export const pageConfigApi = () => {
  return async (dispatch) => {
    let response = await api(`/pageConfig/all`, {}, 'get');
    if (response && response.status === 200) {
      dispatch(setPageConfig(response.data.data));
    } else {
      dispatch(setPageConfig([]));
    }
  };
};

export const getBannerApi = (type, page = 1, limit = 10) => {
  return async (dispatch) => {
    let response = await api(
      `/banner?type=${type}&page=${page}&limit=${limit}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      let data = response.data.data;
      switch (type) {
        case 'Home_Sponsor':
          dispatch(setHomeSponsor(data.banners, data.total));
          break;
        case 'Home_Congress':
          dispatch(setHomeCongress(data.banners, data.total));
          break;
        case 'Home_Banner':
          dispatch(setHomeBanner(data.banners, data.total));
          break;
        case 'Home_Header':
          dispatch(setHomeHeader(data.banners, data.total));
          break;
        default:
          dispatch(setHomeHeader(data.banners, data.total));
      }
      data.page = page;
      data.limit = limit;
      return data;
    } else {
      toastr.error('Something Went Wrong. Try Again');
      return {};
    }
  };
};

export const deleteBanner = async (id) => {
  let response = await api(`/banner/${id}`, {}, 'delete');
  if (response && response.status === 200) {
    toastr.success('Banner deleted successfully');
  } else {
    toastr.error('Something Went Wrong. Try Again');
  }
};

export const addBanner = async (data) => {
  let response = await api(`/banner`, data, 'post');
  if (response && response.status === 200) {
    toastr.success('Banner added successfully');
    history.goBack();
  } else {
    toastr.error('Something Went Wrong. Try Again');
  }
};

export const addInvoice = (id, invoice) => {
  return async (dispatch) => {
    let response = await api(`/addPrevInvoice/${id}/${invoice}`, {}, 'patch');
    if (response.status === 200) {
      toastr.success('Added to cart successfully');
    } else {
      toastr.error('Something Went Wrong. Try Again');
    }
  };
};

export const b2bBlockMenuApi = () => {
  return async (dispatch) => {
    let response = await api(`/menu/admin?type=B2B_HOME`, {}, 'get');
    if (response && response.status === 200) {
      dispatch(setB2bBlocksData(response.data.data));
    } else {
      setB2bBlocksData(setIGMenu([]));
    }
  };
};

export const allCatalogueProductsApi = (id, search = '', searchType = '') => {
  return async (dispatch) => {
    let response = await api(
      `/allProductsWithPricing/${id}?search=${search}&searchType=${searchType}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(setAllCatalogueProducts(response.data.data));
    } else {
      dispatch(setAllCatalogueProducts([]));
    }
  };
};

export const getLoginBackground = () => {
  return async (dispatch) => {
    let response = await api(`/cms/background`, {}, 'get');
    if (response && response.status === 200) {
      if (response.data.data && response.data.data.fileUrl) {
        dispatch(setLoginBackground(response.data.data.fileUrl));
      } else {
        dispatch(setLoginBackground(''));
      }
    } else {
      toastr.error('Login background not added');
    }
  };
};

export const addLoginBackground = (data) => {
  return async (dispatch) => {
    let response = await api(`/cms/background`, data, 'post');
    if (response && response.status === 200) {
      toastr.success('Login background added successfully');
    } else {
      toastr.error('Login background not added');
    }
  };
};

export const addShippingNote = (data) => {
  return async (dispatch) => {
    let response = await api(`/order/shippingNote`, data, 'post');
    if (response && response.status === 200) {
      toastr.success('Shipping Note added successfully');
    } else {
      toastr.error('Shipping Note not added');
    }
  };
};

export const updatePageApi = (id, data) => {
  return async (dispatch) => {
    let response = await api(`/page/admin/${id}`, data, 'patch');
    if (response && response.status === 200) {
      toastr.success('Page Updated Successfully');
    } else {
      toastr.error('Page Not Updated');
    }
  };
};

export const deletePageApi = (id) => {
  return async (dispatch) => {
    let response = await api(`/page/admin/${id}`, {}, 'delete');
    if (response && response.status === 200) {
      toastr.success('Page Deleted Successfully');
    } else {
      toastr.error('Page Not Deleted');
    }
  };
};

export const addPageApi = (data) => {
  return async (dispatch) => {
    let response = await api(`/page`, data, 'post');
    if (response && response.status === 201) {
      toastr.success('Page Created Successfully');
      history.goBack();
    } else {
      toastr.error('Page Not Created');
    }
  };
};

export const allPagesApi = () => {
  return async (dispatch) => {
    let response = await api(`/pages`, {}, 'get');
    if (response && response.status === 200) {
      dispatch(setAllPages(response.data.data));
    } else {
      dispatch(setAllPages([]));
    }
  };
};

export const pagesListApi = (
  search = '',
  page = 1,
  limit = 10,
  sig = '',
  mailer = ''
) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };

    let response = await api(
      `/page/admin?limit=${data.limit}&page=${page}&search=${search}&sig=${sig}&mailer=${mailer}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setPages(response.data.data, page, limit, response.data.totalCount)
      );
      return response.data;
    } else {
      dispatch(setPages([]));
      return {};
    }
  };
};

export const addStoreApi = (data) => {
  return async (dispatch) => {
    let response = await api(`/store`, data, 'post');
    if (response && response.status === 201) {
      toastr.success('Store Created Successfully');
      history.goBack();
    } else {
      toastr.error('Store Not Created');
    }
  };
};

export const storeListApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };

    let response = await api(
      `/store?limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setStores(response.data.data, page, limit, response.data.totalCount)
      );
    } else {
      dispatch(setStores([]));
    }
  };
};

export const updateStoreApi = (id, data) => {
  return async (dispatch) => {
    let response = await api(`/store/${id}`, data, 'patch');
    if (response && response.status === 200) {
      toastr.success('Store Updated Successfully');
    } else {
      toastr.error('Store Not Updated');
    }
  };
};

export const deleteStoreApi = (id) => {
  return async (dispatch) => {
    let response = await api(`/store/${id}`, {}, 'delete');
    if (response && response.status === 200) {
      toastr.success('Store Deleted Successfully');
    } else {
      toastr.error('Store Not Deleted');
    }
  };
};

export const careersListApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };

    let response = await api(
      `/careers?limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setCareers(response.data.data, page, limit, response.data.totalNews)
      );
    } else {
      dispatch(setCareers([]));
    }
  };
};

export const updateCareerApi = (id, data) => {
  return async (dispatch) => {
    let response = await api(`/careers/${id}`, data, 'patch');
    if (response && response.status === 200) {
      toastr.success('Career Updated Successfully');
      history.goBack();
    } else {
      toastr.error('Career Not Updated');
    }
  };
};

export const deleteCareerApi = (id) => {
  return async (dispatch) => {
    let response = await api(`/careers/${id}`, {}, 'delete');
    if (response && response.status === 200) {
      toastr.success('Career Deleted Successfully');
    } else {
      toastr.error('Career Not Deleted');
    }
  };
};

export const addCareerApi = (data) => {
  return async (dispatch) => {
    let response = await api(`/careers`, data, 'post');
    if (response && response.status === 201) {
      toastr.success('Career Created Successfully');
      history.goBack();
    } else {
      toastr.error('Career Not Created');
    }
  };
};

export const addMenuApi = (data) => {
  return async (dispatch) => {
    let response = await api(`/menu`, data, 'post');
    if (response && response.status === 201) {
      toastr.success('Menu Created Successfully');
      history.goBack();
    } else {
      toastr.error('Menu Not Created');
    }
  };
};

export const deleteMenuApi = (id) => {
  return async (dispatch) => {
    let response = await api(`/menu/admin/${id}`, {}, 'delete');
    if (response && response.status === 200) {
      toastr.success('Menu Deleted Successfully');
    } else {
      toastr.error('Menu Not Deleted');
    }
  };
};

export const updateMenuApi = (id, data) => {
  return async (dispatch) => {
    let response = await api(`/menu/admin/${id}`, data, 'patch');
    if (response && response.status === 200) {
      toastr.success('Menu Updated Successfully');
    } else {
      toastr.error('Menu Not Updated');
    }
  };
};

export const updateMenuOrderApi = (data) => {
  return async (dispatch) => {
    let response = await api(`/menu/admin/order`, data, 'patch');
    if (response && response.status === 200) {
      toastr.success('Menu Order Updated Successfully');
    } else {
      toastr.error('Menu Order Not Updated');
    }
  };
};
export const getAllMenu = (search = '', page = 1, limit = 1000) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/menu/admin?limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      let sa_menu = [];
      let hcp_menu = [];
      let pr_menu = [];
      let ce_menu = [];
      response.data.data.map((r) => {
        if (r.menuType === 'Hc_Professionals') {
          hcp_menu.push(r);
        } else if (r.menuType === 'Patient_Resources') {
          pr_menu.push(r);
        } else if (r.menuType === 'Sa_Heart') {
          sa_menu.push(r);
        } else {
          ce_menu.push(r);
        }
        return r;
      });
      dispatch(setHCPMenu(hcp_menu, page, limit, response.data.totalCount));
      dispatch(setPRMenu(pr_menu, page, limit, response.data.totalCount));
      dispatch(setSAMenu(sa_menu, page, limit, response.data.totalCount));
      dispatch(setCEMenu(ce_menu, page, limit, response.data.totalCount));
    } else {
      dispatch(setHCPMenu([]));
    }
  };
};
export const igMenuListApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };

    let response = await api(
      `/menu/admin?type=Interest_Groups&limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setIGMenu(response.data.data, page, limit, response.data.totalCount)
      );
    } else {
      dispatch(setIGMenu([]));
    }
  };
};
export const hcpMenuListApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/menu/admin?type=Hc_Professionals&limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setHCPMenu(response.data.data, page, limit, response.data.totalCount)
      );
    } else {
      dispatch(setHCPMenu([]));
    }
  };
};
export const prMenuListApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };

    let response = await api(
      `/menu/admin?type=Patient_Resources&limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setPRMenu(response.data.data, page, limit, response.data.totalCount)
      );
    } else {
      dispatch(setPRMenu([]));
    }
  };
};
export const ceMenuListApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };

    let response = await api(
      `/menu/admin?type=Congress_Events&limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setCEMenu(response.data.data, page, limit, response.data.totalCount)
      );
    } else {
      dispatch(setCEMenu([]));
    }
  };
};
export const saMenuListApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };

    let response = await api(
      `/menu/admin?type=Sa_Heart&limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setSAMenu(response.data.data, page, limit, response.data.totalCount)
      );
    } else {
      dispatch(setSAMenu([]));
    }
  };
};

export const newsApi = (search = '', page = 1, limit = 10, home) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };

    let response = await api(
      `/news?limit=${data.limit}&page=${page}&search=${search}&home=${home}&sort=articleDate DESC`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setNews(response.data.data, page, limit, response.data.totalNews)
      );
    } else {
      dispatch(setNews([]));
    }
  };
};

export const promotionApi = (
  storeId = '101',
  page = 1,
  limit = 10,
  role = 'Admin',
  account = ''
) => {
  return async (dispatch) => {
    const data = {
      limit,
      page,
    };
    let response = await api(
      `/promotion/${storeId}?limit=${data.limit}&page=${data.page}&role=${role}&account=${account}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setPromotion(
          response.data.data.products,
          page,
          limit,
          response.data.totalPromos,
          response.data.data.promos
        )
      );
      // }
    } else {
      dispatch(setPromotion([]));
    }
  };
};

export const marketingItemApi = (
  search = '',
  page = 1,
  limit = 10,
  sort = 'createdAt DESC'
) => {
  return async (dispatch) => {
    const data = {
      page,
      limit,
    };
    let response = await api(
      `/items?type=marketing&limit=${data.limit}&page=${page}&search=${search}&sort=${sort}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setMarketingItem(
          response.data.data,
          page,
          limit,
          response.data.totalArticles
        )
      );
      // }
    } else {
      dispatch(setMarketingItem([]));
    }
  };
};
export const JournalsApi = (search = '', page = 1, limit = 10, home) => {
  return async (dispatch) => {
    const data = {
      page,
      limit,
    };
    let response = await api(
      `/items?type=journals&limit=${data.limit}&page=${page}&search=${search}&sort=articleDate DESC&home=${home}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setJournalsItem(
          response.data.data,
          page,
          limit,
          response.data.totalArticles
        )
      );
      // }
    } else {
      dispatch(setJournalsItem([]));
    }
  };
};

export const eventsApi = (
  search = '',
  page = 1,
  limit = 10,
  home,
  member = false
) => {
  return async (dispatch) => {
    const data = {
      page,
      limit,
    };
    let response = await api(
      `/items?type=events&limit=${data.limit}&page=${page}&search=${search}&home=${home}&sort=articleDate ASC&member=${member}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setEventsItem(
          response.data.data,
          page,
          limit,
          response.data.totalArticles
        )
      );
      return response;
      // }
    } else {
      dispatch(setEventsItem([]));
    }
  };
};
export const imagesItemApi = (
  search = '',
  page = 1,
  limit = 10,
  sort = 'createdAt DESC'
) => {
  return async (dispatch) => {
    const data = {
      page,
      limit,
    };
    let response = await api(
      `/items?type=images&limit=${data.limit}&page=${page}&search=${search}&sort=${sort}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setImagesItem(
          response.data.data,
          page,
          limit,
          response.data.totalArticles
        )
      );
    } else {
      dispatch(setImagesItem([]));
    }
  };
};
export const trainingItemApi = (
  search = '',
  page = 1,
  limit = 10,
  sort = 'createdAt DESC'
) => {
  return async (dispatch) => {
    const data = {
      page,
      limit,
    };
    let response = await api(
      `/items?type=training&limit=${data.limit}&page=${page}&search=${search}&sort=${sort}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setTrainingItem(
          response.data.data,
          page,
          limit,
          response.data.totalArticles
        )
      );
      // }
    } else {
      dispatch(setTrainingItem([]));
    }
  };
};
export const NewsLettersApi = (search = '', page = 1, limit = 10, home) => {
  return async (dispatch) => {
    const data = {
      page,
      limit,
    };
    let response = await api(
      `/items?type=newsletters&limit=${data.limit}&page=${page}&search=${search}&sort=articleDate DESC&home=${home}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setNewsLettersItem(
          response.data.data,
          page,
          limit,
          response.data.totalArticles
        )
      );
    } else {
      dispatch(setNewsLettersItem([]));
    }
  };
};

export const trainingItemByCategoryApi = (
  search = '',
  page = 1,
  limit = 10,
  categories = [],
  sort = 'createdAt DESC'
) => {
  return async (dispatch) => {
    const data = {
      page,
      limit,
    };
    let response = await api(
      `/itemsByCategory?type=training&limit=${data.limit}&page=${page}&search=${search}&sort=${sort}`,
      { categories },
      'post'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setTrainingItem(
          response.data.data,
          page,
          limit,
          response.data.totalArticles
        )
      );
      // }
    } else {
      dispatch(setTrainingItem([]));
    }
  };
};

export const marketingItemByCategoryApi = (
  search = '',
  page = 1,
  limit = 10,
  categories = [],
  sort = 'createdAt DESC'
) => {
  return async (dispatch) => {
    const data = {
      page,
      limit,
    };
    let response = await api(
      `/itemsByCategory?type=marketing&limit=${data.limit}&page=${page}&search=${search}&sort=${sort}`,
      { categories },
      'post'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setMarketingItem(
          response.data.data,
          page,
          limit,
          response.data.totalArticles
        )
      );
      // }
    } else {
      dispatch(setMarketingItem([]));
    }
  };
};

export const marketingCategoryApi = () => {
  return async (dispatch) => {
    let response = await api(`/category?type=marketing`, {}, 'get');
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(setMarketingCategory(response.data.data));
      // }
    } else {
      dispatch(setMarketingCategory([]));
    }
  };
};

export const imagesCategoryApi = () => {
  return async (dispatch) => {
    let response = await api(`/category?type=images`, {}, 'get');
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(setImagesCategory(response.data.data));
      // }
    } else {
      dispatch(setImagesCategory([]));
    }
  };
};

export const trainingCategoryApi = () => {
  return async (dispatch) => {
    let response = await api(`/category?type=training`, {}, 'get');
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(setTrainingCategory(response.data.data));
      // }
    } else {
      dispatch(setTrainingCategory([]));
    }
  };
};

export const activePromotionApi = () => {
  return async (dispatch) => {
    let response = await api(`/activePromotion`, {}, 'get');
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(setActivePromotion(response.data.data[0]));
      // }
    } else {
      dispatch(setActivePromotion({}));
    }
  };
};

export const subscriberApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/subscriber?limit=${data.limit}&page=${page}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setSubscriber(
          response.data.data,
          page,
          limit,
          response.data.totalSubscribers
        )
      );
      // }
    } else {
      dispatch(setSubscriber([]));
    }
  };
};

export const CatalogueApi = (
  search = '',
  page = 1,
  limit = 10,
  sort = 'createdAt DESC',
  filter = { promotion: '', categoryIds: [], topic: [], documentType: '' }
) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/resources/items?limit=${data.limit}&page=${page}&search=${search}&sort=${sort}`,
      filter,
      'post'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setCatalogue(response.data.data, page, limit, response.data.totalItems)
      );
      // }
    } else {
      dispatch(setCatalogue([]));
    }
  };
};

export const myCatalogueApi = (
  id,
  customer,
  search = '',
  page = 1,
  limit = 10,
  sort = 'createdAt DESC',
  filter = { category: [], industry: [], brand: [], promo: [] },
  searchType = '',
  startPrice = '',
  endPrice = ''
) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/productsWithPricing/${id}/${customer}?limit=${
        data.limit
      }&page=${page}&search=${search}&field=${sort.split(',')[0]}&direction=${
        sort.split(',')[1]
      }`,
      { filter, searchType, startPrice, endPrice },
      'post'
    );
    if (response && response.status === 200) {
      // console.log(response, 'response');
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setMyCatalogue(
          response.data.data,
          page,
          limit,
          response.data.totalProducts,
          filter,
          sort,
          searchType,
          startPrice,
          endPrice
        )
      );
      // }
    } else {
      dispatch(setMyCatalogue([]));
    }
  };
};

export const auditTrailApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/audit?limit=${data.limit}&page=${page}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setAuditTrail(
          response.data.data,
          page,
          limit,
          response.data.totalAudits
        )
      );
      // }
    } else {
      dispatch(setAuditTrail([]));
    }
  };
};

export const StockSheetApi = (id, search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/stocksheetsByAccount/${id}?limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setStockSheet(
          response.data.data,
          page,
          limit,
          response.data.totalStockSheets
        )
      );
      // }
    } else {
      dispatch(setStockSheet([]));
    }
  };
};

export const RelationApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/relatedProducts?limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setRelation(
          response.data.data,
          page,
          limit,
          response.data.totalProducts
        )
      );
      // }
    } else {
      dispatch(setRelation([]));
    }
  };
};

export const CompanyUsersApi = (
  search = '',
  page = 1,
  limit = 10,
  store = '',
  type = 'B2B'
) => {
  return async (dispatch) => {
    let userData = JSON.parse(localStorage.getItem('member'));
    store =
      userData.role === 'store_admin' || userData.storeCode
        ? userData.storeCode
        : '';
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/user?limit=${data.limit}&page=${page}&search=${search}&store=${store}&type=${type}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setCompanyUsers(
          response.data.data,
          page,
          limit,
          response.data.totalUsers
        )
      );
      // }
    } else {
      dispatch(setCompanyUsers([]));
    }
  };
};
export const updateUserApi = async (id, data) => {
  return await api(`/user-account/${id}`, data, 'patch');
};
export const CustomerCompanyUsersApi = (
  id,
  search = '',
  page = 1,
  limit = 10
) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/userByAccount/${id}?limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      if (response.data.data.length > 0)
        dispatch(
          setCompanyUsers(
            response.data.data,
            page,
            limit,
            response.data.totalUsers
          )
        );
      else dispatch(setCompanyUsers([]));
      // }
    } else {
      dispatch(setCompanyUsers([]));
    }
  };
};

export const CompanyApi = (page = 1, search = '', skip = '', store = '') => {
  return async (dispatch) => {
    let userData = JSON.parse(localStorage.getItem('member'));
    store =
      userData.role === 'store_admin' || userData.storeCode
        ? userData.storeCode
        : '';
    // console.log('Here');
    let response = await api(
      `/accounts?page=${page}&limit=${10}&searchKey=${search}&skip=${skip}&store=${store}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(setCompany(response.data.data));
      return response.data;
      // }
    } else {
      dispatch(setCompany([]));
      return {};
    }
  };
};

export const RepContactAccount = (id, search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/accountsForRep/${id}?limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      if (response.data.data.length > 0)
        dispatch(
          setRepContactAccount(
            response.data.data,
            page,
            limit,
            response.data.totalAccounts
          )
        );
      else dispatch(setRepContactAccount([]));
      // }
    } else {
      dispatch(setRepContactAccount([]));
    }
  };
};

export const RepContact = (id, search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/repContactsByAccount/${id}?limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      if (response.data.data.length > 0)
        dispatch(
          setRepContact(
            response.data.data,
            page,
            limit,
            response.data.totalRepContacts
          )
        );
      else dispatch(setRepContact([]));
      // }
    } else {
      dispatch(setRepContact([]));
    }
  };
};

export const SwitchAccountApi = (
  userId,
  accountId,
  search = '',
  page = 1,
  limit = 10
) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/accountByUser/${userId}/${accountId}?limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      if (response.data.data.length > 0)
        dispatch(
          setSwitchAccount(
            response.data.data,
            page,
            limit,
            response.data.totalAccounts
          )
        );
      else dispatch(setSwitchAccount([]));
      // }
    } else {
      dispatch(setSwitchAccount([]));
    }
  };
};

export const AdminSwitchAccountApi = (
  search = '',
  page = 1,
  limit = 10,
  store = ''
) => {
  return async (dispatch) => {
    let userData = JSON.parse(localStorage.getItem('member'));
    store =
      userData.role === 'store_admin' || userData.storeCode
        ? userData.storeCode
        : '';
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/accounts?limit=${data.limit}&page=${page}&searchKey=${search}&store=${store}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      if (response.data.data.length > 0)
        dispatch(
          setSwitchAccount(
            response.data.data,
            page,
            limit,
            response.data.totalAccounts
          )
        );
      else dispatch(setSwitchAccount([]));
      // }
    } else {
      dispatch(setSwitchAccount([]));
    }
  };
};

export const BackOrderApi = (id, search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };

    let response = await api(
      `/uniqueOrders/${id}?backOrder=true&limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setQuotes(response.data.data, page, limit, response.data.totalOrders)
      );
    } else {
      dispatch(setQuotes([]));
    }
  };
};

export const OpenOrderApi = (id, search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };

    let response = await api(
      `/uniqueOrders/${id}?backOrder=false&limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setPayment(response.data.data, page, limit, response.data.totalOrders)
      );
    } else {
      dispatch(setPayment([]));
    }
  };
};

export const InvoiceApi = (
  id,
  search = '',
  page = 1,
  limit = 10,
  start = 0,
  end = 0
) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };

    let response = await api(
      `/uniqueInvoice/${id}?limit=${data.limit}&page=${page}&search=${search}`,
      { start, end },
      'post'
    );
    // console.log(response, 'response api');
    if (response && response.status === 200) {
      dispatch(
        setInvoice(response.data.data, page, limit, response.data.totalInvoices)
      );
    } else {
      dispatch(setInvoice([]));
    }
  };
};

export const CartApi = (id) => {
  return async (dispatch) => {
    let response = await api(`/carts/${id}`, {}, 'get');
    if (response && response.status === 200) {
      if (response.data.data) {
        dispatch(setCart(response.data.data));
        dispatch(
          setCartCount(
            response.data.data.productsWithQty
              ? response.data.data.productsWithQty.length
              : 0
          )
        );
      } else {
        dispatch(setCart([]));
        dispatch(setCartCount(0));
      }
    } else {
      dispatch(setCart([]));
    }
  };
};

export const AdminDashboardUsersApi = () => {
  return async (dispatch) => {
    // console.log('Here');
    let response = await api(`/dashboard-users`, {}, 'get');
    if (response && response.status === 200) {
      dispatch(setAdminDashboardUsers(response.data.data));
      // }
    } else {
      dispatch(setAdminDashboardUsers({}));
    }
  };
};
export const AdminDashboardOrdersApi = () => {
  return async (dispatch) => {
    // console.log('Here');
    let response = await api(`/dashboard-orders`, {}, 'get');
    if (response && response.status === 200) {
      dispatch(setAdminDashboardOrders(response.data.data));
      // }
    } else {
      dispatch(setAdminDashboardOrders({}));
    }
  };
};
export const AdminDashboardRevenueApi = () => {
  return async (dispatch) => {
    // console.log('Here');
    let response = await api(`/dashboard-revenue`, {}, 'get');
    if (response && response.status === 200) {
      dispatch(setAdminDashboardRevenue(response.data.data));
      // }
    } else {
      dispatch(setAdminDashboardRevenue({}));
    }
  };
};
export const AdminDashboardProductsApi = () => {
  return async (dispatch) => {
    // console.log('Here');
    let response = await api(`/dashboard-products`, {}, 'get');
    if (response && response.status === 200) {
      dispatch(setAdminDashboardProducts(response.data.data));
      // }
    } else {
      dispatch(setAdminDashboardProducts({}));
    }
  };
};

export const CategoryListApi = () => {
  return async (dispatch) => {
    // console.log('Here');
    let response = await api(`/productsCategories`, {}, 'get');
    if (response && response.status === 200) {
      let array = [];
      response.data.data.map((one) => {
        return array.push({ id: one.id, title: one.title });
      });

      dispatch(setCategoryList(array));
    } else {
      dispatch(setCategoryList([]));
    }
  };
};

// export const transactionApi = (search = "", page = 1, limit = 10) => {
//   return async dispatch => {
//     const data = {
//       search,
//       page,
//       limit
//     };
//     let skip=(page-1)*limit;
//     // const artistResult = await api("admin/get/artist", data, "post");
//     let response={};
//     if(skip > 0 ){
//       response = await api(`/payment/receipts?limit=${data.limit}&skip=${skip}`,{}, 'get');
//     }
//     else{
//       response = await api(`/payment/receipts?limit=${data.limit}`,{}, 'get');
//     }
//     if (response && response.status === 200) {
//       // let countResponse = await api('/admin/count',{},'get');
//       // if(countResponse.status === 200){
//         // console.log(response.data.count);
//         dispatch(
//           setTransactions(
//               response.data.data,
//             page,
//             limit,
//             response.data.count
//           )
//         );
//       // }
//     } else {
//       dispatch(setTransactions([]));
//     }
//   };
// };

// export const serviceProviderApi = (search = "", page = 1, limit = 10) => {
//   return async dispatch => {
//     console.log("page",page);
//     const data = {
//       search,
//       page,
//       limit
//     };
//     let skip=(page-1)*limit;
//     // const artistResult = await api("admin/get/artist", data, "post");
//     let response={};
//     if(skip > 0 ){
//       response = await api(`/auth/firebase/admin/user?role=service-provider&limit=${data.limit}&skip=${skip}`,{}, 'get');
//     }
//     else{
//       response = await api(`/auth/firebase/admin/user?role=service-provider&limit=${data.limit}`,{}, 'get');
//     }
//     if (response && response.status === 200) {
//         dispatch(
//           setServiceProviders(
//               response.data.data.user,
//             page,
//             limit,
//             response.data.data.count
//           )
//         );
//     } else {
//       dispatch(setServiceProviders([]));
//     }
//   };
// };

// export const pendingEnquiryApi = (search = "", page = 1, limit = 10) => {
//   return async dispatch => {
//     console.log("page",page);
//     const data = {
//       search,
//       page,
//       limit
//     };
//     let skip=(page-1)*limit;
//     // const artistResult = await api("admin/get/artist", data, "post");
//     let response={};
//     if(skip > 0 ){
//       response = await api(`/auth/firebase/admin/status/user?verificationStatus=pending&limit=${data.limit}&skip=${skip}`,{}, 'get');
//     }
//     else{
//       response = await api(`/auth/firebase/admin/status/user?verificationStatus=pending&limit=${data.limit}`,{}, 'get');
//     }
//     if (response && response.status === 200) {
//         dispatch(
//           setPendingEnquiries(
//               response.data.data.user,
//             page,
//             limit,
//             response.data.data.count
//           )
//         );
//     } else {
//       dispatch(setPendingEnquiries([]));
//     }
//   };
// };

// export const categoryApi = () => {
//   return async dispatch => {

//     let  response = await api(`/order/category`,{}, 'get');
//     if (response && response.status === 200) {
//         dispatch(
//           setCategories(
//               response.data.data
//           )
//         );
//     } else {
//       dispatch(setCategories([]));
//     }
//   };
// };

export const updateNews = async (id, data) =>
  await api(`/news/${id}`, data, 'patch');

export const addNews = async (data) => {
  try {
    let response = await api(`/news`, { ...data, type: 'news' }, 'post');
    return response;
  } catch (error) {
    toastr.error('Something Went Wrong. Try Again');
  }
};
export const addEvents = async (data) => {
  try {
    let response = await api(
      `/news`,
      { ...data, type: 'events', platform: 'BOTH' },
      'post'
    );
    return response;
  } catch (error) {
    toastr.error('Something Went Wrong. Try Again');
  }
};

export const deleteNews = async (id) => await api(`/news/${id}`, {}, 'delete');

export const updatePromotion = async (id, data) =>
  await api(`/promotion/${id}`, data, 'patch');

export const updatePromotionStatus = async (id, data) =>
  await api(`/promotion/${id}`, data, 'patch');

export const addPromotion = async (data) => {
  try {
    let response = await api(`/promotion`, data, 'post');
    return response;
  } catch (error) {
    toastr.error('Something Went Wrong. Try Again');
  }
};

export const deletePromotion = async (id, data, codes) =>
  await api(`/promotion/${id}`, { products: data, codes: codes }, 'delete');

export const updateSubscriber = async (id, data) =>
  await api(`/subscriber/${id}`, data, 'patch');

export const addSubscriber = async (data) => {
  try {
    let response = await api(`/subscriber`, data, 'post');
    return response;
  } catch (error) {
    toastr.error('Something Went Wrong. Try Again');
  }
};

export const deleteSubscriber = async (id) =>
  await api(`/subscriber/${id}`, {}, 'delete');

export const createCategory = async (data) =>
  await api(`/category`, data, 'post');

export const updateCategory = async (id, data) =>
  await api(`/category/${id}`, data, 'patch');

export const deleteCategory = async (id) =>
  await api(`/category/${id}`, {}, 'delete');

export const deleteOldCategory = async (categoryId, subCategoriesId) =>
  await api(`/categoryUnchecked`, { categoryId, subCategoriesId }, 'delete');

export const deleteItem = async (id) => await api(`/items/${id}`, {}, 'delete');
export const createItem = async (data) => await api(`/items`, data, 'post');
export const updateItem = async (id, data) =>
  await api(`/items/${id}`, data, 'patch');
export const getItemByTitle = async (id) =>
  await api(`/items-by-title`, { title: id }, 'post');
export const createImages = async (data) => await api(`/images`, data, 'post');
export const updateImages = async (id, data) =>
  await api(`/images/${id}`, data, 'patch');

export const updateProductImage = async (id, image) =>
  await api(`/products/${id}`, { image: image }, 'patch');

export const updateProduct = async (id, data) =>
  await api(`/updateImageSlug/${id}`, data, 'patch');

export const customerRegistration = async (data) =>
  await api(`/member-registration`, data, 'post');

export const mailRegisteredCustomer = async (data) =>
  await api(`/sendCustomerRegistrationForm`, { id: data }, 'post');

export const SubmitStandForm = async (data) =>
  await api(`/sendEventStandUpForm`, data, 'post');

export const SubmitStandAgreementForm = async (data) =>
  await api(`/sendStandAgreementForm`, data, 'post');

export const createStockSheet = async (data) =>
  await api(`/stocksheets`, data, 'post');

export const deleteStockSheet = async (id) =>
  await api(`/stocksheets/${id}`, {}, 'delete');

export const updateStockSheet = async (data, id) =>
  await api(`/stocksheets/${id}`, data, 'patch');

export const getAllProducts = async () => await api(`/allproducts`, {}, 'get');

export const createRelation = async (data) =>
  await api(`/relatedProducts`, data, 'post');

export const updateRelation = async (data, id) =>
  await api(`/relatedProducts/${id}`, data, 'patch');

export const deleteRelation = async (id) =>
  await api(`/relatedProducts/${id}`, {}, 'delete');

export const createUser = async (data) => await api(`/user`, data, 'post');

export const updateUser = async (id, data) =>
  await api(`/user/${id}`, data, 'patch');

export const deleteUser = async (id, account) =>
  await api(`/user/${id}`, { account: account }, 'delete');

export const createRepContact = async (data) =>
  await api(`/createRepContacts`, data, 'post');

export const updateCart = async (id, data) => {
  let response = await api(`/carts/${id}`, data, 'patch');
  // console.log(response, 'updatedCart');
  return response;
};

export const postOrder = async (data) => {
  // console.log(data);
  let response = await api(`/orderProcess`, data, 'post');
  return response;
};
export const sendProductData = async (id, data) => {
  let response = await api(
    `/sendProductDetail`,
    { account: id, details: data },
    'post'
  );
  return response;
};

export const resendPassword = async (email) => {
  let response = await api(`/resendPassword`, { email: email }, 'post');
  return response;
};

export const getProductIdCart = async (account, code) => {
  let response = await api(
    `/productsWithPricing/${account}/product/${code}`,
    {},
    'get'
  );
  return response;
};

export const stocksheetToCart = async (account, id) => {
  let response = await api(`/stocksheets/${id}/${account}`, {}, 'get');
  // console.log(response, 'stocksheetToCart');
  return response;
};

export const allRepContact = (
  search = '',
  page = 1,
  limit = 10,
  start = 0,
  end = 0
) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/repContacts?limit=${data.limit}&page=${page}&search=${search}`,
      { start, end },
      'post'
    );
    // console.log(response);
    if (response && response.status === 200) {
      if (response.data.data.length > 0)
        dispatch(
          setRepContact(
            response.data.data,
            page,
            limit,
            response.data.totalRepContacts
          )
        );
      else dispatch(setRepContact([]));
      // }
    } else {
      dispatch(setRepContact([]));
    }
  };
};

export const updateAccountApi = (id) => {
  return async (dispatch) => {
    // let response = await api(`/accountFromSyspro/${id}`, {}, 'patch');
    // if(respon)
  };
};

export const printStocksheet = (id, accountId) => {
  return async (dispatch) => {
    let response = await api(`/printStocksheet/${id}/${accountId}`, {}, 'get');
    return response;
  };
};
export const printCart = (id) => {
  return async (dispatch) => {
    let response = await api(`/printCart/${id}`, {}, 'get');
    return response;
  };
};
export const printProducts = (id, products) => {
  return async (dispatch) => {
    let response = await api(
      `/productsPrint/${id}`,
      { products: products },
      'post'
    );
    // console.log(response);
    return response;
  };
};
export const updateNewsStatusApi = (data) => {
  return async (dispatch) => {
    let response = await api(`/news-status/${data}`, {}, 'patch');
    if (response && response.status === 200) {
      toastr.success('Display Status Updated');
    } else {
      toastr.error('Display Status Not Updated');
    }
  };
};
export const SIGListApi = (
  search = '',
  page = 1,
  limit = 10,
  store = '',
  type = 'B2B',
  role = 'sig'
) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/user?limit=${data.limit}&page=${page}&search=${search}&store=${store}&type=${type}&role=${role}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setCompanyUsers(
          response.data.data,
          page,
          limit,
          response.data.totalUsers
        )
      );
      // }
    } else {
      dispatch(setCompanyUsers([]));
    }
  };
};
export const linksHCPApi = (
  search = '',
  page = 1,
  limit = 10,
  sort = 'order ASC'
) => {
  return async (dispatch) => {
    const data = {
      page,
      limit,
    };
    let response = await api(
      `/items?type=hcp_links&limit=${data.limit}&page=${page}&search=${search}&sort=${sort}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setHCPLinksItem(
          response.data.data,
          page,
          limit,
          response.data.totalArticles
        )
      );
    } else {
      dispatch(setHCPLinksItem([]));
    }
  };
};
export const linksPRApi = (
  search = '',
  page = 1,
  limit = 10,
  sort = 'order ASC'
) => {
  return async (dispatch) => {
    const data = {
      page,
      limit,
    };
    let response = await api(
      `/items?type=pr_links&limit=${data.limit}&page=${page}&search=${search}&sort=${sort}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setPRLinksItem(
          response.data.data,
          page,
          limit,
          response.data.totalArticles
        )
      );
    } else {
      dispatch(setPRLinksItem([]));
    }
  };
};
export const verifyMembersApi = (id, emails) => {
  return async (dispatch) => {
    let response = await api(
      `/check-members-email/${id}`,
      { memberEmails: emails },
      'post'
    );
    if (response && response.status === 200) {
      return response;
    } else {
      toastr.error(response.data.error);
    }
  };
};
export const createMember = (data) => {
  return async (dispatch) => {
    let response = await api(`/create-member`, data, 'post');
    if (response && response.status === 201) {
      return response;
    } else {
      toastr.error(response.data.error);
    }
  };
};

export const updateMembershipHospital = async (memberId, data) => {
  let response = await api(
    `/update-membership-hospitals/${memberId}`,
    data,
    'patch'
  );
  if (response && response.status === 200) {
    toastr.success('Hospitals updated successfully.');
    return true;
  } else {
    toastr.error('Hospital not updated. Please try after some time.');
    return false;
  }
};
export const getAllFeesApi = async () => await api(`/fees`, {}, 'get');
export const getAllHospitalsApi = async () =>
  await api(`/hospitals`, {}, 'get');
export const verifyNewMember = async (data) =>
  await api(
    `/verify-member-email?email=${data.email}&token=${data.token}`,
    {},
    'get'
  );
export const getOneMember = async (id) => {
  let response = await api(`/members/${id}`, {}, 'get');
  if (response && response.status !== 200) {
    history.push('/');
  }
  return response;
};
export const getOneMemberActive = async () => await api(`/member`, {}, 'get');
export const createTransaction = async (data) =>
  await api(`/transactions`, data, 'post');
export const updateTransaction = async (id, data) =>
  await api(`/transactions/${id}`, data, 'post');
export const sageMemberApi = async (data) =>
  await api(`/send-invoice`, data, 'post');

export const getMemberMotivation = async (id) =>
  await api(`/member-motivation/${id}`, {}, 'get');

export const ProductCategoryApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };

    let response = await api(
      `/attributes?type=Category&limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );

    if (response && response.status === 200) {
      dispatch(
        setCategory(response.data.data, page, limit, response.data.totalCount)
      );
    } else {
      dispatch(setCategory([]));
    }
  };
};
export const ProductFileTypeApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };

    let response = await api(
      `/attributes?type=File_Type&limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );

    if (response && response.status === 200) {
      dispatch(
        setFileType(response.data.data, page, limit, response.data.totalCount)
      );
    } else {
      dispatch(setFileType([]));
    }
  };
};
export const ProductTopicApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };

    let response = await api(
      `/attributes?type=Topic&limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );

    if (response && response.status === 200) {
      dispatch(
        setTopic(response.data.data, page, limit, response.data.totalCount)
      );
    } else {
      dispatch(setTopic([]));
    }
  };
};

export const CartB2CApi = () => {
  return async (dispatch) => {
    let response = JSON.parse(localStorage.getItem('SAHeartCart'));
    // console.log('response', response);
    if (response) {
      dispatch(setCart(response));
      dispatch(setCartCount(response.length ? response.length : 0));
    } else {
      dispatch(setCart([]));
      dispatch(setCartCount(0));
    }
  };
};
export const createInvoiceB2C = (data) => {
  return async (dispatch) => {
    let response = await api(`/invoice-b2c/create`, data, 'post');
    if (response && response.status === 200) {
      toastr.success('Mail sent successfully');
      return response;
    } else {
      toastr.error('Something went wrong');
    }
  };
};
export const getCustomerFromSage = () => {
  return async (dispatch) => {
    let response = await api(`/account/getFromSage`, {}, 'get');
    if (response && response.status === 200) {
      dispatch(setCompany(response.data.data));
      // return response;
    } else {
      dispatch(setCompany({}));
      toastr.error('Something went wrong');
    }
  };
};
export const getUpcomingMembership = async (id) => {
  let response = await api(`/upcoming-membership/${id}`, {}, 'get');
  if (response && response.status === 200) {
    return response.data.data;
    // return response;
  } else {
    toastr.error('Something went wrong');
    return null;
  }
};

export const getSummary = () => {
  return async (dispatch) => {
    let response = await api(
      `/account/getSummary`,
      { toDate: new Date() },
      'post'
    );
    if (response && response.status === 200) {
      dispatch(setSummary(response.data.data));
      // return response;
    }
  };
};
export const getQuotes = (page = 1, limit = 10) => {
  return async (dispatch) => {
    let response = await api(`/quotes`, {}, 'get');
    if (response && response.status === 200) {
      dispatch(
        setQuotes(response.data.data, page, limit, response.data.totalItems)
      );
      // return response;
    } else {
      dispatch(setQuotes([]));
    }
  };
};
export const getOneQuote = async (id) => {
  let response = await api(`/quotes/${id}`, {}, 'get');
  return response;
};
export const getInvoices = (page = 1, limit = 10, start, end, doc) => {
  return async (dispatch) => {
    let response = await api(
      `/invoice?start=${start}&end=${end}&doc=${doc}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setInvoice(response.data.data, page, limit, response.data.totalItems)
      );
    } else {
      dispatch(setInvoice([]));
    }
  };
};
export const getOneInvoice = async (id) => {
  let response = await api(`/invoice/${id}`, {}, 'get');
  return response;
};
export const printInvoice = async (id) => {
  let response = await api(`/invoice/export/${id}`, {}, 'get');
  return response;
};

export const getPayment = (page = 1, limit = 10) => {
  return async (dispatch) => {
    let response = await api(`/accountPayment`, {}, 'get');
    if (response && response.status === 200) {
      dispatch(
        setPayment(response.data.data, page, limit, response.data.totalItems)
      );
      // return response;
    } else {
      dispatch(setPayment([]));
    }
  };
};

export const getOnePayment = async (id) => {
  let response = await api(`/accountPayment/${id}`, {}, 'get');
  return response;
};

export const contactUsApi = async (data) => await api(`/enquiry`, data, 'post');

export const getMemberSearch = (
  page = 1,
  limit = 10,
  search,
  speciality,
  province,
  interests
) => {
  return async (dispatch) => {
    let response = await api(
      `/member-search?page=${page}&limit=${limit}&search=${search}`,
      { province, speciality, interests },
      'post'
    );
    if (response && response.status === 200) {
      dispatch(
        setMemberSearch(
          response.data.data,
          page,
          limit,
          response.data.totalMembers
        )
      );
    } else {
      dispatch(setMemberSearch([]));
    }
  };
};
export const acceptMotivation = async (data) =>
  await api(
    `/accept-motivation?email=${data.email}&token=${data.token}`,
    {},
    'post'
  );

export const updateMyDetailsApi = async (data) =>
  await api(`/members/my-details`, data, 'patch');

export const updateBillingAddApi = async (data) =>
  await api(`/members/billing-details`, data, 'patch');

export const getOneTransaction = async (id) =>
  await api(`/transaction/${id}`, {}, 'get');

export const renewMembership = async (data) => {
  let response = await api('/renew-membership', data, 'post');
  return response;
};

export const getMyMembership = async (data) => {
  let response = await api('/my-membership', {}, 'get');
  return response;
};

export const topUpMembership = async (data) => {
  let response = await api('/top-up-membership', data, 'post');
  return response;
};

export const allMembersApi = async () => {
  return await api('/members-all?accType=billing_entity', {}, 'get');
};
export const accountMembers = async () => {
  return await api(`/member-on-this-account`, {}, 'get');
};
export const resetPassword = async (data) => {
  return await api(`/members/reset-password`, data, 'patch');
};
export const SearchApi = async (searchType, search) => {
  return await api(`/web-search?search=${search}`, { searchType }, 'post');
};
export const ItemById = async (id) => {
  return await api(`/resources/items/${id}`, {}, 'get');
};
export const PageById = async (id) => {
  return await api(`/page/admin/${id}`, {}, 'get');
};
export const groupTopupApi = async (data) => {
  return await api(`/top-up-group-membership`, data, 'post');
};
export const groupRenewApi = async (data) => {
  return await api(`/renew-group-membership`, data, 'post');
};
export const checkBillingName = async (data) => {
  // console.log(data, 'bname in api');
  return await api(`/members/check-billing-name`, data, 'post');
};
export const checkPendingGroupMembership = async () => {
  return await api(`/pending-group-membership`, {}, 'get');
};
export const congressClickLog = async () => {
  return await api(`/congress-log/create`, {}, 'get');
};
export const createJournalMember = async (data) => {
  return await api(`/create/journal-member`, data, 'post');
};
