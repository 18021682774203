export const setCompany = (value) => {
  return {
    type: "SET_COMPANY",
    payload: { data: value },
  };
};
export const setSummary = (value) => {
  return {
    type: "SET_SUMMARY",
    payload: { data: value },
  };
};
