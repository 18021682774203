const initState = {
  company: {},
  summary: [
    {
      Current: 0,

      Days30: 0,
      Days60: 0,
      Days90: 0,
      Days120Plus: 0,
    },
  ],
};

const CompanyReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_COMPANY":
      return {
        ...state,
        company: action.payload.data,
      };
    case "SET_SUMMARY":
      return {
        ...state,
        summary: action.payload.data,
      };
    case "RESET_COMPANY_STATE":
      return {
        ...state,
        ...initState,
      };
    default:
      return { ...state };
  }
};

export default CompanyReducer;
