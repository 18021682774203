const initState = {
  myCatalogue: [],
  page: 1,
  limit: 20,
  count: 0,
  filter: { category: [], industry: [], brand: [], promo: [] },
  sort: 'createdAt,-1',
  allProducts: [],
  startPrice: 0,
  endPrice: 0,
  searchType: '',
};

const MyCatalogueReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_MYCATALOGUE':
      return {
        ...state,
        myCatalogue: action.payload.data,
        page: action.payload.page,
        limit: action.payload.limit,
        count: action.payload.count,
        filter: action.payload.filter,
        sort: action.payload.sort,
        startPrice: action.payload.startPrice,
        endPrice: action.payload.endPrice,
        searchType: action.payload.searchType,
      };
    case 'SET_ALL_CATALOGUE_PRODUCTS':
      return {
        ...state,
        allProducts: action.payload.data,
      };
    case 'SET_FILTER_MYCATALOGUE':
      return {
        ...state,
        filter: action.payload.data,
      };
    case 'RESET_MYCATALOGUE':
      return {
        ...state,
        ...initState,
      };
    default:
      return { ...state };
  }
};

export default MyCatalogueReducer;
