const initState = {
  imagesItem: [],
  page: 1,
  limit: 10,
  count: 0,
  imagesCategory: [],
  linksHCPItem: [],
  linksPRItem: [],
};

const ImagesReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_IMAGES_CATEGORY":
      return {
        ...state,
        imagesCategory: action.payload.data,
      };
    case "RESET_IMAGES_CATEGORY_STATE":
      return {
        ...state,
        ...initState,
      };
    case "SET_IMAGES_ITEM":
      return {
        ...state,
        imagesItem: action.payload.data,
        page: action.payload.page,
        limit: action.payload.limit,
        count: action.payload.count,
      };
    case "SET_HCPLINKS_ITEM":
      return {
        ...state,
        linksHCPItem: action.payload.data,
        page: action.payload.page,
        limit: action.payload.limit,
        count: action.payload.count,
      };
    case "SET_PRLINKS_ITEM":
      return {
        ...state,
        linksPRItem: action.payload.data,
        page: action.payload.page,
        limit: action.payload.limit,
        count: action.payload.count,
      };
    case "RESET_STATE_IMAGES_ITEM":
      return {
        ...state,
        ...initState,
      };
    default:
      return { ...state };
  }
};

export default ImagesReducer;
