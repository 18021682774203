const initState = {
  motivationRedirect: false,
};

const ConfigReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_MOTIVATION_CONFIG':
      return {
        ...state,
        motivationRedirect: action.payload.data,
      };
    case 'RESET_STATE':
      return {
        ...state,
        ...initState,
      };
    default:
      return { ...state };
  }
};

export default ConfigReducer;
