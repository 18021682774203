export const setSAMenu = (value, page, limit, count) => {
  return {
    type: "SET_SA_MENU",
    payload: { data: value, page, limit, count },
  };
};
export const setIGMenu = (value, page, limit, count) => {
  return {
    type: "SET_IG_MENU",
    payload: { data: value, page, limit, count },
  };
};
export const setHCPMenu = (value, page, limit, count) => {
  return {
    type: "SET_HCP_MENU",
    payload: { data: value, page, limit, count },
  };
};
export const setCEMenu = (value, page, limit, count) => {
  return {
    type: "SET_CE_MENU",
    payload: { data: value, page, limit, count },
  };
};
export const setPRMenu = (value, page, limit, count) => {
  return {
    type: "SET_PR_MENU",
    payload: { data: value, page, limit, count },
  };
};
