export const setImagesCategory = (value) => {
  return {
    type: "SET_IMAGES_CATEGORY",
    payload: { data: value },
  };
};

export const setHCPLinksItem = (value, page, limit, count) => {
  return {
    type: "SET_HCPLINKS_ITEM",
    payload: { data: value, page, limit, count },
  };
};
export const setPRLinksItem = (value, page, limit, count) => {
  return {
    type: "SET_PRLINKS_ITEM",
    payload: { data: value, page, limit, count },
  };
};
export const setImagesItem = (value, page, limit, count) => {
  return {
    type: "SET_IMAGES_ITEM",
    payload: { data: value, page, limit, count },
  };
};
