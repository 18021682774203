export const setCategory = (value, page, limit, count) => {
  return {
    type: "SET_CATEGORY",
    payload: { data: value, page, limit, count },
  };
};
export const setTopic = (value, page, limit, count) => {
  return {
    type: "SET_TOPIC",
    payload: { data: value, page, limit, count },
  };
};
export const setFileType = (value, page, limit, count) => {
  return {
    type: "SET_FILE_TYPE",
    payload: { data: value, page, limit, count },
  };
};
