import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <footer>
        <Container fluid className="custom-width">
          <div className="footer-data">
            <div className="one">
              <Link to="/contactus">contact us</Link>
              <span></span>
              <Link to="/">
                <address className="mb-0">
                  PO BOX 3213 MATIELAND 7602 <br />
                  SOUTH AFRICA
                </address>
              </Link>
            </div>
            <div className="two">
              <a href="tel:+27(0)218896129" className="mb-0">
                TEL: +27 (0)21 - 889 6129
              </a>
              <a href="fax:+27(0)218896129">FAX:+27 (0)21 - 889 6129</a>
              <Link to="/contactus" className="btn btn-secondary">
                message us
              </Link>
            </div>
            <div className="three">
              <div className="mar-bottom">
                <Link
                  to="#"
                  onClick={() =>
                    window.open(
                      'https://www.saheart.org/api/get-file/Images?file=01ada976-b04a-4ddd-bb23-f78eba1a2464.pdf',
                      '_blank'
                    )
                  }
                >
                  Disclaimer
                </Link>
                <Link
                  to="#"
                  onClick={() =>
                    window.open(
                      'https://www.saheart.org/api/get-file/Images?file=25fd9e6a-1ed9-4cee-967c-c1dea1ee6e58.pdf',
                      '_blank'
                    )
                  }
                >
                  Terms and Conditions
                </Link>
                <Link
                  to="#"
                  onClick={() =>
                    window.open(
                      'https://www.saheart.org/api/get-file/Images?file=d56587cd-56fe-4897-b769-56d163496c00.pdf',
                      '_blank'
                    )
                  }
                >
                  Privacy Clause
                </Link>
              </div>
              <span className="d-md-block d-none">
                Copyright © {moment(new Date()).format('YYYY')} SA Heart
                Association. All Rights Reserved.
              </span>
            </div>
          </div>
          <div className="copy-right-section d-md-none d-block">
            <span>
              Copyright © {moment(new Date()).format('YYYY')} SA Heart
              Association. All Rights Reserved.
            </span>
          </div>
        </Container>
      </footer>
    );
  }
}

export default Footer;
