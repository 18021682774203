import { combineReducers } from "redux"
import { Menu } from "./menuReducer";
import {Careers} from "./careersReducer";
import {Store} from "./storeReducer";
import {Pages} from "./pageReducer";
import {Background} from "./backgroundReducer";


const authReducers = combineReducers({
Menu,
Careers,
Store,
Pages,
Background
})

export default authReducers
