import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
function CommonLoader(loading) {
  return (
    <>
      <div className="common-loader">
        <ClipLoader color={"#0f3063"} loading={loading} size={40} margin={10} />
      </div>
    </>
  );
}

export default CommonLoader;
