
  
const initState = {
    transactions: [],
    page: 1,
    limit: 10,
    count: 0
  };
  
  const TransactionReducer = (state = initState, action) => {
    switch (action.type) {
      case 'SET_TRANSACTIONS':
        return {
          ...state,
          transactions: action.payload.data,
          page: action.payload.page,
          limit: action.payload.limit,
          count: action.payload.count
        };
      case 'RESET_TRANSACTIONS':
        return {
          ...state,
          ...initState
        };
      default:
        return { ...state };
    }
  };
  
  export default TransactionReducer;
  