const initState = {
  adminDashboardUsers: {},
  adminDashboardOrders: {},
  adminDashboardProducts: {},
  adminDashboardRevenue: {},
};

const AdminDashboardReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_ADMIN_DASHBOARD_USERS':
      return {
        ...state,
        adminDashboardUsers: action.payload.data,
      };
    case 'SET_ADMIN_DASHBOARD_PRODUCTS':
      return {
        ...state,
        adminDashboardProducts: action.payload.data,
      };
    case 'SET_ADMIN_DASHBOARD_ORDERS':
      return {
        ...state,
        adminDashboardOrders: action.payload.data,
      };
    case 'SET_ADMIN_DASHBOARD_REVENUE':
      return {
        ...state,
        adminDashboardRevenue: action.payload.data,
      };
    case 'RESET_ADMIN_DASHBOARD_STATE':
      return {
        ...state,
        ...initState,
      };
    default:
      return { ...state };
  }
};

export default AdminDashboardReducer;
