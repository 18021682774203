const initState = {
    careersList: [],
    career: {},
    page: 1,
    limit :10,
    count : 0
  };
  export const Careers = (state = initState, action) => {
    switch (action.type) {
      case "SET_CAREERS": {
        return {
          ...state,
          careersList: action.payload.data,
          page: action.payload.page,
          limit: action.payload.limit,
          count: action.payload.count,
        };
      }
      default: {
        return state;
      }
    }
  };
  