const initState = {
    loginBackgroundUrl:""
  };
  export const Background = (state = initState, action) => {
    switch (action.type) {
      case "SET_LOGIN_BACKGROUND": {
        return {
          ...state,
          loginBackgroundUrl:action.payload.data
        };
      }
      default: {
        return state;
      }
    }
  };
  