import history from '../../../app/history';
import axios from 'axios';
import { auth } from '../../../api/auth';
import { toastr } from 'react-redux-toastr';
import { store } from '../../storeConfig/store';
import { api } from '../../../api/api';
// const initAuth0 = new auth0.WebAuth(configAuth)

// export const submitLoginWithFireBase = (email, password, remember) => {
//   let firebaseAuth = firebase.auth()
//   return dispatch => {
//     let userEmail = null,
//       loggedIn = false
//     firebaseAuth
//       .signInWithEmailAndPassword(email, password)
//       .then(result => {
//         firebaseAuth.onAuthStateChanged(async user => {
//           // result.user.updateProfile({
//           //   displayName: "Admin"
//           // })
//           console.log("firebaseLogin", user.ma);
//           let token = user.ma;
//           let name = result.user.displayName

//           if (user && remember) {
//             firebase
//               .auth()
//               .setPersistence(firebase.auth.Auth.Persistence.SESSION)
//               .then(async () => {

//                 let data = {
//                   email: userEmail,
//                   isSignedIn: loggedIn,
//                   remember: true,
//                   loggedInWith: "firebase",
//                   token: token
//                 };
//                 dispatch({
//                   type: "LOGIN_WITH_EMAIL",
//                   payload: data
//                 })
//                 let response = await auth('/auth/firebase/login/email-direct',{},'post');
//                 // console.log(response.data.data[0].role);
//                 if(response && response.data.data.role === "admin"){
//                   data = {...data, ...response.data.data};
//                   data.token = token
//                   dispatch({
//                     type: "LOGIN_WITH_EMAIL",
//                     payload: data
//                   })
//                   dispatch({
//                     type: "SET_LOGIN_FLAG",
//                     payload: {data: true}
//                   })
//                   dispatch({
//                     type: "CHANGE_ROLE",
//                     payload: {data: "admin"}
//                   })
//                   localStorage.setItem("member",JSON.stringify(data));
//                   history.push("/")
//                   toast.success("Login Successful");

//                 }
//                 else{
//                   dispatch({
//                     type: "LOGIN_WITH_EMAIL",
//                     payload: {}
//                   })
//                   history.push("/login")
//                 }
//               })
//           }
//           else if (user) {
//             userEmail = user.email
//             loggedIn = true
//             let data={
//               email: userEmail,
//               isSignedIn: loggedIn,
//               loggedInWith: "firebase",
//               token: token
//             };

//             dispatch({
//               type: "LOGIN_WITH_EMAIL",
//               payload: data
//             })
//             let response = await auth('/auth/firebase/login/email-direct',{},'post');
//             // console.log(response.data.data[0].role);
//             if(response && response.data.data.role === "admin"){
//               data = {...data, ...response.data.data};
//               data.token = token;
//               dispatch({
//                 type: "LOGIN_WITH_EMAIL",
//                 payload: data
//               })
//               dispatch({
//                 type: "SET_LOGIN_FLAG",
//                 payload: {data: true}
//               })
//               dispatch({
//                 type: "CHANGE_ROLE",
//                 payload: {data: "admin"}
//               })
//               localStorage.setItem("member",JSON.stringify(data));
//               history.push("/")
//               toast.success("Login Successful");
//             }
//             else{
//               dispatch({
//                 type: "LOGIN_WITH_EMAIL",
//                 payload: {}
//               })
//               history.push("/login")
//             }
//           }
//           // history.push("/")
//         })
//       })
//       .catch(error => {
//         console.log("error",error)
//         if(error.code === "auth/wrong-password" || error.code === "auth/user-not-found"){
//           toast.error(`Invalid Credentials`);
//         }
//         // else if(error.code === "auth/user-not-found"){
//         //   toast.error("User not found");
//         // }
//       })
//   }
// }

export const setLoginFlag = (value) => {
  return {
    type: 'SET_LOGIN_FLAG',
    payload: { data: value },
  };
};

// export const loginWithFB = () => {
//   return dispatch => {
//     let provider = new firebase.auth.FacebookAuthProvider()
//     provider.setCustomParameters({
//       display: "popup"
//     })
//     firebaseAuth
//       .signInWithPopup(provider)
//       .then(result => {
//         // This gives you a Facebook Access Token. You can use it to access the Facebook API.
//         let token = result.credential.accessToken,
//           // The signed-in user info.
//           user = result.user.email
//         dispatch({
//           type: "LOGIN_WITH_FB",
//           payload: {
//             user,
//             token,
//             loggedInWith: "firebase"
//           }
//         })
//         if (user) history.push("/")
//       })
//       .catch(error => {
//         console.log(error)
//       })
//   }
// }

// export const loginWithTwitter = () => {
//   return dispatch => {
//     let provider = new firebase.auth.TwitterAuthProvider()
//     firebaseAuth
//       .signInWithPopup(provider)
//       .then(function(result) {
//         let token = result.credential.accessToken,
//           user = result.user.email,
//           name = result.user.displayName,
//           photoUrl = result.user.photoURL
//         dispatch({
//           type: "LOGIN_WITH_TWITTER",
//           payload: {
//             user,
//             name,
//             photoUrl,
//             token,
//             loggedInWith: "firebase"
//           }
//         })
//         history.push("/")
//       })
//       .catch(function(error) {
//         console.log(error)
//       })
//   }
// }

// export const loginWithGoogle = () => {
//   return dispatch => {
//     let provider = new firebase.auth.GoogleAuthProvider()
//     firebaseAuth
//       .signInWithPopup(provider)
//       .then(function(result) {
//         console.log(result.user)
//         let token = result.credential.accessToken,
//           user = result.user.email,
//           name = result.user.displayName,
//           photoUrl = result.user.photoURL
//         dispatch({
//           type: "LOGIN_WITH_GOOGLE",
//           payload: {
//             email: user,
//             name: name,
//             photoUrl,
//             token,
//             loggedInWith: "firebase"
//           }
//         })
//         history.push("/")
//       })
//       .catch(function(error) {
//         console.log(error)
//       })
//   }
// }

// export const loginWithGithub = () => {
//   return dispatch => {
//     let provider = new firebase.auth.GithubAuthProvider()
//     firebaseAuth
//       .signInWithPopup(provider)
//       .then(function(result) {
//         let token = result.credential.accessToken,
//           user = result.user.email,
//           name = result.additionalUserInfo.username,
//           photoUrl = result.user.photoURL

//         dispatch({
//           type: "LOGIN_WITH_GITHUB",
//           payload: {
//             user,
//             name,
//             photoUrl,
//             token,
//             loggedInWith: "firebase"
//           }
//         })
//         history.push("/")
//       })
//       .catch(function(error) {
//         console.log(error)
//       })
//   }
// }

export const loginWithJWT = (user) => {
  return async (dispatch) => {
    // console.log(user);
    let response = await auth('/members/login', user, 'post');
    //             // console.log(response.data.data[0].role);
    if (response && response.status === 200) {
      let loggedInUser = response.data.data;

      dispatch({
        type: 'LOGIN_WITH_JWT',
        payload: { loggedInUser, loggedInWith: 'jwt' },
      });
      dispatch({
        type: 'SET_LOGIN_FLAG',
        payload: { data: true },
      });

      localStorage.setItem('member', JSON.stringify(loggedInUser));

      toastr.success('Login Successful');
    } else {
      dispatch({
        type: 'LOGIN_WITH_JWT',
        payload: {},
      });
      toastr.error(response.data.error);
    }
    return response;
  };
  //   })
  //   .catch(err => console.log(err))
  // };
};
export const resendPassword = async (email) => {
  let response = await api(
    `/members/resend-password`,
    { email: email },
    'post'
  );
  return response;
};
export const logoutWithJWT = () => {
  return async (dispatch) => {
    let isLoggedIn = store.getState().auth.login;
    let id = isLoggedIn.values.loggedInUser.id;
    // let response = await auth(`/logout/${id}`, {}, "get");
    // console.log('response', response);

    // console.log('id', id);
    dispatch({
      type: 'SET_LOGIN_FLAG',
      payload: { data: false },
    });

    dispatch({ type: 'LOGOUT_WITH_JWT', payload: {} });

    localStorage.removeItem('member');
    toastr.success('Logout Successful');
    setTimeout(() => {
      history.push('/');
    });
  };
};

export const logoutWithFirebase = (user) => {
  return (dispatch) => {
    dispatch({ type: 'LOGOUT_WITH_FIREBASE', payload: {} });
    dispatch({
      type: 'SET_LOGIN_FLAG',
      payload: { data: false },
    });
    dispatch({
      type: 'CHANGE_ROLE',
      payload: { data: '' },
    });
    localStorage.removeItem('member');
    toastr.success('Logout Successful');
    history.push('/');
  };
};

export const changeRole = (role) => {
  return (dispatch) => dispatch({ type: 'CHANGE_ROLE', userRole: role });
};
