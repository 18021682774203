export const setMarketingItem = (value, page, limit, count) => {
  return {
    type: "SET_MARKETING_ITEM",
    payload: { data: value, page, limit, count },
  };
};
export const setJournalsItem = (value, page, limit, count) => {
  return {
    type: "SET_JOURNALS_ITEM",
    payload: { data: value, page, limit, count },
  };
};
export const setNewsLettersItem = (value, page, limit, count) => {
  return {
    type: "SET_NEWSLETTERS_ITEM",
    payload: { data: value, page, limit, count },
  };
};
export const setEventsItem = (value, page, limit, count) => {
  return {
    type: "SET_EVENTS_ITEM",
    payload: { data: value, page, limit, count },
  };
};
