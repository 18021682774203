const initState = {
  customerDetails: {},
};
export const CustomerRegistration = (state = initState, action) => {
  switch (action.type) {
    case "ADD_CUSTOMER_DETAILS": {
      // console.log(action, state, "values");
      return {
        ...state,
        customerDetails: action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
};
