const initState = {
    pageConfig: [],
  };
  
  const PageConfigReducer = (state = initState, action) => {
    switch (action.type) {
      case "SET_PAGE_CONFIG":
        return {
          ...state,
          pageConfig: action.payload.data,
        };
      default:
        return { ...state };
    }
  };
  
  export default PageConfigReducer;
  