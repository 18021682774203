const initState = {
  marketingItem: [],
  journalsItem: [],
  newsLettersItem: [],
  eventsItem: [],
  page: 1,
  limit: 10,
  count: 0,
};

const MarketingItemReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_MARKETING_ITEM":
      return {
        ...state,
        marketingItem: action.payload.data,
        page: action.payload.page,
        limit: action.payload.limit,
        count: action.payload.count,
      };
    case "SET_JOURNALS_ITEM":
      return {
        ...state,
        journalsItem: action.payload.data,
        page: action.payload.page,
        limit: action.payload.limit,
        count: action.payload.count,
      };
    case "SET_NEWSLETTERS_ITEM":
      return {
        ...state,
        newsLettersItem: action.payload.data,
        page: action.payload.page,
        limit: action.payload.limit,
        count: action.payload.count,
      };
    case "SET_EVENTS_ITEM":
      return {
        ...state,
        eventsItem: action.payload.data,
        page: action.payload.page,
        limit: action.payload.limit,
        count: action.payload.count,
      };
    case "RESET_STATE_MARKETING_ITEM":
      return {
        ...state,
        ...initState,
      };
    default:
      return { ...state };
  }
};

export default MarketingItemReducer;
