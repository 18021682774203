const initState = {
  Catalogue: [],
  page: 1,
  limit: 30,
  count: 0,
  search: "",
  promotion: "",
  documentType: [],
  topic: [],
  categoryIds: [],
};

const CatalogueReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_CATALOGUE":
      return {
        ...state,
        Catalogue: action.payload.data,
        count: action.payload.count,
      };
    case "SET_CATALOGUE_PAGE":
      return {
        ...state,
        page: action.payload,
      };
    case "SET_CATALOGUE_LIMIT":
      return {
        ...state,
        limit: action.payload,
      };
    case "SET_CATALOGUE_SEARCH":
      return {
        ...state,
        search: action.payload,
      };
    case "SET_CATALOGUE_DOCUMENT":
      return {
        ...state,
        documentType: action.payload,
      };
    case "SET_CATALOGUE_TOPIC":
      return {
        ...state,
        topic: action.payload,
      };
    case "SET_CATALOGUE_PROMOTION":
      return {
        ...state,
        promotion: action.payload,
      };
    case "SET_CATALOGUE_CATEGORY":
      return {
        ...state,
        categoryIds: action.payload,
      };
    case "RESET_CATALOGUE_FILTER":
      return {
        ...state,
        page: 1,
        limit: 30,
        promotion: "",
        categoryIds: [],
        topic: [],
        documentType: [],
        search: "",
      };
    case "RESET_CATALOGUE_FILTER_ONLY":
      return {
        ...state,
        categoryIds: [],
        topic: [],
        documentType: [],
      };
    default:
      return { ...state };
  }
};

export default CatalogueReducer;
