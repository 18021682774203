// import external modules
import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import authReducers from './auth/index';
import newsReducer from './news/newsReducer';
import promotionReducer from './promotion/promotionReducer';
import subscriberReducer from './subscriber/subscriberReducer';
import CatalogueReducer from './Catalogue/CatalogueReducer';
import myCatalogueReducer from './myCatalogue/myCatalogueReducer';
import { CustomerRegistration } from './customerRegistration/customerRegistrationReducer';
import marketingCategoryReducer from './marketingCategory/marketingCategoryReducer';
import trainingCategoryReducer from './trainingCategory/trainingCategoryReducer';
import marketingItemReducer from './marketingItem/marketingItemReducer';
import trainingItemReducer from './trainingItem/trainingItemReducer';
import auditTrailReducer from './auditTrail/auditTrailReducer';
import stockSheetReducer from './stockSheet/stockSheetReducer';
import relationReducer from './relation/relationReducer';
import companyReducer from './company/companyReducer';
import companyUsersReducer from './companyUsers/companyUsersReducer';
import customerCompanyUsersReducer from './customerCompanyUsers/customerCompanyUsersReducer';
import switchAccountReducer from './switchAccount/switchAccountReducer';
import repContactAccountReducer from './repContactAccount/repContactAccountReducer';
import paymentReducer from './payment/paymentReducer';
import quoteReducer from './quote/quoteReducer';
import invoiceReducer from './invoice/invoiceReducer';
import cartReducer from './cart/cartReducer';
import cmsReducer from './b2cCms/index';
import b2bReducer from './B2bCms/index';
import BannerReducer from './banner/bannerReducer';
import PageConfigReducer from './pageConfig/pageConfigReducer';
import ImagesReducer from './images/ImagesReducer';
import ProductAttributesReducer from '../reducers/productAttributes/productAttributesReducer';
// import internal(own) modules

import LayoutReducer from './layoutReducer';
import RepContactReducer from './repContact/repContactReducer';
import AdminDashboardReducer from './adminDashboard/adminDashboardReducer';
import SearchMyCatalogueReducer from './searchMyCatalogue/searchMyCatalogueReducer';
import MemberSearchReducer from './memberSearch/memberSearchReducer';
import TransactionReducer from './transaction/transactionReducer';
import ConfigReducer from './config/configReducer';

const rootReducer = combineReducers({
  toastr: toastrReducer, // <- Mounted at toastr.
  layout: LayoutReducer,
  auth: authReducers,
  news: newsReducer,
  customer: CustomerRegistration,
  promotion: promotionReducer,
  subscriber: subscriberReducer,
  Catalogue: CatalogueReducer,
  myCatalogue: myCatalogueReducer,
  marketingCategory: marketingCategoryReducer,
  trainingCategory: trainingCategoryReducer,
  marketingItem: marketingItemReducer,
  trainingItem: trainingItemReducer,
  auditTrail: auditTrailReducer,
  stockSheet: stockSheetReducer,
  relation: relationReducer,
  company: companyReducer,
  companyUsers: companyUsersReducer,
  customerCompanyUsers: customerCompanyUsersReducer,
  switchAccount: switchAccountReducer,
  repContactAccount: repContactAccountReducer,
  repContact: RepContactReducer,
  payment: paymentReducer,
  quote: quoteReducer,
  invoice: invoiceReducer,
  cart: cartReducer,
  adminDashboard: AdminDashboardReducer,
  searchMyCatalogue: SearchMyCatalogueReducer,
  cms: cmsReducer,
  b2bCms: b2bReducer,
  banner: BannerReducer,
  pageConfig: PageConfigReducer,
  memberSearch: MemberSearchReducer,
  transactions: TransactionReducer,
  images: ImagesReducer,
  attributes: ProductAttributesReducer,
  config: ConfigReducer,
});

export default rootReducer;
