
  
const initState = {
  promotion: [],
  page: 1,
  limit: 10,
  count: 0,
  promotionDetails:{},
  activePromotion:{}
  };
  
  const PromotionReducer = (state = initState, action) => {
    switch (action.type) {
      case 'SET_PROMOTION':
        return {
          ...state,
          promotion: action.payload.data,
          page: action.payload.page,
          limit: action.payload.limit,
          count: action.payload.count,
          promotionDetails : action.payload.promos
        };
        case 'SET_ACTIVE_PROMOTION':
          return {
            ...state,
            activePromotion: action.payload.data
          };
      case 'RESET_STATE_PROMOTION':
        return {
          ...state,
          ...initState
        };
      default:
        return { ...state };
    }
  };
  
  export default PromotionReducer;
  