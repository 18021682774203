const initState = {
    menuList:[]
  };
  export const B2bBlocks = (state = initState, action) => {
    switch (action.type) {
      case "SET_B2B_BLOCKS_DATA": {
        return {
          ...state,
          menuList:action.payload.data
        };
      }
      default: {
        return state;
      }
    }
  };
  