export const setHomeHeader = (value, total) => {
  return {
    type: "SET_HOME_HEADER",
    payload: { data: value, total },
  };
};

export const setHomeBanner = (value, total) => {
  return {
    type: "SET_HOME_BANNER",
    payload: { data: value, total },
  };
};

export const setHomeSponsor = (value, total) => {
  return {
    type: "SET_HOME_SPONSOR",
    payload: { data: value, total },
  };
};

export const setHomeCongress = (value, total) => {
  return {
    type: "SET_HOME_CONGRESS",
    payload: { data: value, total },
  };
};
