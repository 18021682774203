const initState = {
  homeHeader: [],
  totalHomeHeader: 0,
  homeBanner: [],
  totalHomeBanner: 0,
  homeSponsor: [],
  totalHomeSponsor: 0,
  homeCongress: [],
  totalHomeCongress: 0,
};

const BannerReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_HOME_HEADER":
      return {
        ...state,
        homeHeader: action.payload.data,
        totalHomeHeader: action.payload.total,
      };
    case "SET_HOME_CONGRESS":
      return {
        ...state,
        homeCongress: action.payload.data,
        totalHOmeCongress: action.payload.total,
      };
    case "SET_HOME_SPONSOR":
      return {
        ...state,
        homeSponsor: action.payload.data,
        totalHomeSponsor: action.payload.total,
      };

    case "SET_HOME_BANNER":
      return {
        ...state,
        homeBanner: action.payload.data,
        totalHomeBanner: action.payload.total,
      };
    default:
      return { ...state };
  }
};

export default BannerReducer;
